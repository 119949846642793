import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
// import 'assets/scss/material-ui.scss?v=1.4.0';

import Layout from 'components/layout';
import AboutSection from 'components/Sections/AboutSection';
import Parallax from 'components/Parallax';
import Footer from 'components/Footer/Footer';
import { GridContainer, GridItem as BaseGridItem } from 'components/Grid';
import { landingPageStyles } from 'styles/index.css';

const GridItem = styled(BaseGridItem)`
	border-radius: 10px;
	backdrop-filter: blur(1px);
`;

const About = ({
	classes,
	data: {
		aboutJson: { title: pageTitle },
	},
}) => (
	<Layout pageTitle={pageTitle}>
		<Parallax filter image={require('assets/img/richmond-bg.png')}>
			<div className={classes.container}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={10}>
						<h1 className={classes.title}>How Our Story Began.</h1>
					</GridItem>
				</GridContainer>
			</div>
		</Parallax>
		<div className={classNames(classes.main, classes.mainRaised)}>
			<div className={classes.container}>
				<AboutSection />
			</div>
		</div>
		<Footer />
	</Layout>
);

About.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

export default withStyles(landingPageStyles)(About);

export const query = graphql`
	query AboutpageQuery {
		aboutJson {
			title
		}
	}
`;
