/* eslint-disable react/no-unescaped-entities */
/* eslint-disable quotes */
import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// @material-ui/icons

// core components
import { GridContainer, GridItem } from 'components/Grid/index';

import camerons from '../../assets/img/camerons.jpg';
import { aboutStyle } from './Sections.css';

const AboutSection = ({ classes }) => (
	<div className={classes.section}>
		<GridContainer>
			<GridItem className={classes.centered} sm={6} md={9}>
				<p className={classes.paragraph}>
					ARC Insurance Agency was started in 2013 by Principal Agent,
					Ryan Cameron. After college, Ryan worked in an independent
					agency, but decided to start his own agency offering similar
					products and services to the Richmond area. At ARC Insurance
					we value the uniqueness of the independent agency system
					over a traditional, captive agency, where a agent works off
					a one size fits all carrier model. This helps set ARC
					Insurance Agency apart from other local insurance agencies.
				</p>
				<p className={classes.paragraph}>
					Our unique ability to shop for multiple products and
					coverage for our clients is what sets ARC Insurance Agency
					above the rest of our field. Using our direct carrier
					appointments, ARC agents can provide our customers with
					multiple coverage options for any insurance need, while
					simultaneously seeking out the best value in the market when
					it comes to the price of your coverage.
				</p>
				<p className={classes.paragraph}>
					Our agents and service representatives understand the most
					important part of our job is you, THE CLIENT. We want to
					treat you like a part of our family. If you've ever had the
					experience of feeling like just another number when you call
					your agent or carrier, we can assure you that will not be
					your experience with us. Our goal is to provide you with
					high quality service as the agents of your insurance needs.
					We strive to relay valuable information to you as the
					consumer when making an insurance purchase decision and will
					be here after the purchase to help with anything that arises
					during the life of your policy or for any other questions
					you may have about insurance coverage or product offerings.
				</p>
			</GridItem>
			<GridItem
				style={{ display: 'flex', alignItems: 'center' }}
				sm={6}
				md={3}>
				<img className={classes.imgStyle} src={camerons} alt='camerons' />
			</GridItem>
		</GridContainer>
	</div>
);

AboutSection.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(aboutStyle)(AboutSection);
